<template>
  <div class="homeDashboard" v-mdb-resize:start="checkWidth">
    <!-- SideBar -->
    <mdb-side-nav-2
      color="primary"
      v-model="showSideNav"
      :over="over"
      :sidenav-class="['mdb-color', 'darken-4', 'text-white']"
    >
      <!-- Header -->
      <div slot="header" class="mt-5 pt-4 sideBar">
        <p class="navbar-text text-white my-3">
          <span class="text-white font-weight-bold"
            >{{ userProfile.firstName }} {{ userProfile.name }}</span
          >
        </p>

        <hr class="white" />

        <ul
          slot="content"
          class="custom-content list-unstyled d-flex flex-column align-items-start"
        >
          <li
            :class="{ active: i === active }"
            v-for="(navItem, i) in navigation"
            :key="i"
            class="px-3"
          >
            <mdb-nav-item @click="active = i" :to="navItem.destination">
              <mdb-row class="align-items-center justify-content-start my-2">
                <img
                  :src="active === i ? navItem.icon : navItem.inactiveIcon"
                  height="16"
                  class="mx-2"
                  alt="Navigation icon"
                />
                <p
                  :class="active === i ? 'text-primary' : 'text-navigation'"
                  class="mb-0"
                >
                  {{ navItem.name }}
                </p>
              </mdb-row>
            </mdb-nav-item>
          </li>
        </ul>
      </div>
      <!-- /Header -->

      <!-- NavBar -->
      <mdb-navbar
        slot="nav"
        position="top"
        dark
        :toggler="false"
        :style="{ zIndex: '1061' }"
      >
        <!-- Left -->
        <mdb-navbar-nav left>
          <mdb-icon
            icon="bars"
            class="white-text m-3"
            size="lg"
            @click.native="showSideNav = !showSideNav"
          />
          <mdb-navbar-brand>
            <a href="/dashboard">
              <img
                src="@/assets/logo_my_agora_white.svg"
                height="22"
                alt="Logo My Agora"
                class="m-2"
              />
            </a>
          </mdb-navbar-brand>
        </mdb-navbar-nav>
        <!-- /Left -->

        <!-- Right -->
        <mdb-navbar-nav right>
          <mdb-dropdown tag="li" class="nav-item" anchorClass="p-0">
            <mdb-dropdown-toggle
              tag="a"
              navLink
              class="text-navigation"
              slot="toggle"
              waves-fixed
            >
              <img
                v-if="userProfile.avatarUrl"
                :src="userProfile.avatarUrl"
                class="rounded-circle z-depth-0 image-fit"
                alt="agora_image_utilisateur"
                height="30"
                width="30"
              />
              <img
                v-else
                src="@/assets/ic_user_placeholder.svg"
                alt="Placeholder utilisateur"
                height="20"
                width="20"
              />
              <small class="text-navigation ml-2 mr-2"
                >{{ userProfile.firstName }} {{ userProfile.name }}</small
              >
            </mdb-dropdown-toggle>
            <mdb-dropdown-menu>
              <mdb-dropdown-item
                class="text-navigation"
                waves-fixed
                @click="showModalLogOut = true"
                >Déconnexion</mdb-dropdown-item
              >
            </mdb-dropdown-menu>
          </mdb-dropdown>
        </mdb-navbar-nav>
        <!-- /Right -->
      </mdb-navbar>
      <!-- /NavBar -->

      <!-- Router View -->
      <div slot="main" style="height: 100vh">
        <router-view></router-view>
      </div>
      <!-- /Router View -->
    </mdb-side-nav-2>
    <!-- /SideBar -->

    <!-- Modal -->
    <mdb-modal
      :show="showModalLogOut"
      @close="showModalLogOut = false"
      size="sm"
      class="text-center agora-modal"
      danger
    >
      <mdb-modal-header center :close="false" primary>
        <p class="heading">Déconnexion</p>
      </mdb-modal-header>
      <mdb-modal-body>
        <p class="text-secondary">
          Êtes-vous sur de vouloir vous déconnecter ?
        </p>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="danger" size="sm" @click="showModalLogOut = false"
          >Non</mdb-btn
        >
        <mdb-btn color="danger" size="sm" @click="logout">Oui</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
    <!-- /Modal -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mdbResize } from "mdbvue";
const fb = require("../firebaseConfig.js");

export default {
  name: "HomeDashboard",
  data() {
    return {
      showModalLogOut: false,
      showSideNav: true,
      over: false,
      active: 0,
      navigation: [
        {
          name: "Accueil",
          icon: require("@/assets/ic_carte_active.svg"),
          inactiveIcon: require("@/assets/ic_carte_inactive.svg"),
          destination: "/dashboard",
        },
        {
          name: "Consultations",
          icon: require("@/assets/ic_consultations_active.svg"),
          inactiveIcon: require("@/assets/ic_consultations_inactive.svg"),
          destination: "/dashboard/consultations",
        },
        {
          name: "Agoras",
          icon: require("@/assets/ic_agoras_active.svg"),
          inactiveIcon: require("@/assets/ic_agoras_inactive.svg"),
          destination: "/dashboard/agoras",
        },
        {
          name: "Sondages",
          icon: require("@/assets/ic_surveys_active.svg"),
          inactiveIcon: require("@/assets/ic_surveys_inactive.svg"),
          destination: "/dashboard/surveys",
        },
        {
          name: "Événements",
          icon: require("@/assets/ic_events_active.svg"),
          inactiveIcon: require("@/assets/ic_events_inactive.svg"),
          destination: "/dashboard/events",
        },
        {
          name: "Idées",
          icon: require("@/assets/ic_ideas_active.svg"),
          inactiveIcon: require("@/assets/ic_ideas_inactive.svg"),
          destination: "/dashboard/ideas",
        },
        {
          name: "Signalements",
          icon: require("@/assets/ic_alerts_active.svg"),
          inactiveIcon: require("@/assets/ic_alerts_inactive.svg"),
          destination: "/dashboard/alerts",
        },
        {
          name: "Actualités",
          icon: require("@/assets/ic_news_active.svg"),
          inactiveIcon: require("@/assets/ic_news_inactive.svg"),
          destination: "/dashboard/news",
        },
        {
          name: "Utilisateurs",
          icon: require("@/assets/ic_news_active.svg"),
          inactiveIcon: require("@/assets/ic_news_inactive.svg"),
          destination: "/dashboard/users",
        },
        {
          name: "Commentaires",
          icon: require("@/assets/ic_news_active.svg"),
          inactiveIcon: require("@/assets/ic_news_inactive.svg"),
          destination: "/dashboard/comments",
        },
        {
          name: "Demandes App",
          icon: require("@/assets/ic_news_active.svg"),
          inactiveIcon: require("@/assets/ic_news_inactive.svg"),
          destination: "/dashboard/app-requests",
        },
      ],
      activeChoice: 0,
    };
  },
  computed: {
    ...mapState(["userProfile"]),
  },
  methods: {
    checkWidth() {
      this.over = window.innerWidth < 900;
    },
    logout() {
      fb.auth
        .signOut()
        .then(() => {
          this.$router.push("/");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  directives: {
    mdbResize,
  },
};
</script>

<style lang="scss">
@import "../assets/_custom.scss";
.navbar {
  background-color: $secondary;
}

.navbar i {
  cursor: pointer;
  color: white;
}

.toHide {
  @media (max-width: 1199.98px) {
    display: none;
  }
}

.custom-content li {
  width: 100%;
}

.custom-content li {
  transition: all 0.3s ease-in;
  &:hover,
  &:focus,
  &:active {
    background-color: $secondary-light !important;
  }
}

.specialInput input {
  box-shadow: none;
  border-bottom: none;
  color: $white !important;
  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    border-bottom: none;
    color: $white !important;
  }
}

.specialInput input::placeholder {
  box-shadow: none;
  border-bottom: none;
  color: $navigationBlue !important;
}

.specialInput input[type="text"] {
  box-shadow: 0 0 0 0 transparent !important;
  border-bottom: 0px solid transparent !important;
}
</style>