<template>
  <div id="news" class="main mt-5 p-5">
    <!-- Title -->
    <mdb-row class="justify-content-between align-items-center">
      <h1 class="h3-responsive font-weight-bold">Actualités</h1>
    </mdb-row>
    <!-- /Title -->

    <!-- Table -->
    <div v-if="!isLoading">
      <mdb-input
        class="mb-0"
        size="sm"
        v-model="search"
        label="Rechercher par titre"
      />
      <mdb-datatable-2
        v-model="data"
        hover
        :searching="{ value: search, field: 'title' }"
        striped
        bordered
        hoverColor="primary"
        arrows
        class="my-5"
      />
    </div>
    <!-- /Table -->

    <!-- Loading -->
    <mdb-container
      v-else
      class="d-flex justify-content-center align-items-center"
    >
      <mdb-spinner small crazy />
    </mdb-container>
    <!-- /Loading -->
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { mdbDatatable2 } from "mdbvue";

export default {
  name: "News",
  components: {
    mdbDatatable2,
  },
  data() {
    return {
      search: "",
      isLoading: true,
      data: {
        columns: [
          {
            label: "Date",
            field: "creationDate",
            sort: "asc",
          },
          {
            label: "Title",
            field: "title",
            sort: "asc",
          },
          {
            label: "Statut",
            field: "type",
            sort: "asc",
          },
          {
            label: "Description",
            field: "description",
            sort: "asc",
          },
          {
            label: "CommuneUuid",
            field: "communeUuid",
            sort: "asc",
          },
        ],
        rows: [],
      },
    };
  },
  computed: {
    ...mapState(["news"]),
  },
  watch: {
    news() {
      for (let index = 0; index < this.news.length; index++) {
        const actu = this.news[index];
        var json = {
          creationDate: moment(new Date(actu.creationDate)).format(
            "DD/MM/YYYY"
          ),
          title: actu.title,
          type: actu.type,
          description: actu.description,
          communeUuid: actu.communeUuid,
        };

        this.data.rows.push(json);
      }

      this.isLoading = false;
    },
  },
  created() {
    this.$store.dispatch("getNews");
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";
</style>