import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap-css-only/css/bootstrap.min.css'
import 'mdbvue/lib/mdbvue.css'
import './assets/_custom.scss'
import Vue from 'vue'
import App from './App'
import router from './router'
import { store } from './store.js'
import { auth } from './firebaseConfig'

Vue.config.productionTip = false

// Notify
import Notify from 'mdbvue/lib/components/Notify'
Vue.use(Notify)

// Mdb Vue
import * as mdbvue from 'mdbvue'
for (const component in mdbvue) {
  Vue.component(component, mdbvue[component])
}

let app
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})