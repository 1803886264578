import Vue from 'vue';
import Router from 'vue-router';
import firebase from 'firebase';

// Views
import Home from '@/views/Home';
import HomeDashboard from '@/views/HomeDashboard';

// Agoras
import Agoras from '@/components/agoras/Agoras';
// Alerts
import Alerts from '@/components/alerts/Alerts'
// App Requests
import AppRequests from '@/components/apprequests/AppRequests'
// Comments
import Comments from '@/components/comments/Comments';
// Consultations
import Consultations from '@/components/consultations/Consultations';
// Dashboard
import Dashboard from '@/components/dashboard/Dashboard';
// Events
import Events from '@/components/events/Events'
// Ideas
import Ideas from '@/components/ideas/Ideas'
// News
import News from '@/components/news/News'
// Surveys
import Surveys from '@/components/surveys/Surveys';
// Users
import Users from '@/components/users/Users';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  hash: false,
  routes: [
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/dashboard',
      component: HomeDashboard,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          name: 'home',
          component: Dashboard
        },
        // Agoras
        {
          path: 'agoras',
          name: 'Agoras',
          component: Agoras
        },
        // Alerts
        {
          path: 'alerts',
          name: 'Alerts',
          component: Alerts
        },
        // AppRequests
        {
          path: 'app-requests',
          name: 'AppRequests',
          component: AppRequests
        },
        // Comments
        {
          path: 'comments',
          name: 'Comments',
          component: Comments
        },
        // Consultations
        {
          path: 'consultations',
          name: 'Consultations',
          component: Consultations
        },
        // Events
        {
          path: 'events',
          name: 'Events',
          component: Events
        },
        // Ideas
        {
          path: 'ideas',
          name: 'Ideas',
          component: Ideas
        },
        // News
        {
          path: 'news',
          name: 'News',
          component: News
        },
        // Surveys
        {
          path: 'surveys',
          name: 'Surveys',
          component: Surveys
        },
        // Users
        {
          path: 'users',
          name: 'Users',
          component: Users
        },
      ],
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const currentUser = firebase.auth().currentUser

  if (requiresAuth && !currentUser) {
    next('/')
  } else if (requiresAuth && currentUser) {
    next()
  } else {
    next()
  }
})

export default router

