<template>
  <div id="login">
    <mdb-view>
      <mdb-mask class="d-flex justify-content-center align-items-center">
        <mdb-container>
          <mdb-row>
            <mdb-col
              md="6"
              class="text-center text-md-left animated fadeInLeft"
            >
              <div>
                <img
                  class="my-agora-logo img-fluid"
                  src="@/assets/logo_my_agora_white.svg"
                  alt="logo_my_agora_white"
                />
                <h1 class="h1-responsive white-text font-weight-bold mt-3 mb-5">
                  Espace admin<br />
                  <span>MyAgora</span>
                </h1>
              </div>
            </mdb-col>

            <mdb-col md="6" xl="5" class="text-center animated fadeInRight">
              <mdb-card>
                <mdb-card-body
                  class="z-depth-2 p-5"
                  :class="{
                    'signup-form': !showLoginForm,
                  }"
                >
                  <!-- Login Form -->
                  <form
                    v-if="showLoginForm"
                    novalidate
                    @submit.prevent="checkForm"
                    ref="form"
                  >
                    <h2 class="h3-responsive text-left font-weight-bold">
                      Connexion
                    </h2>
                    <mdb-input
                      id="input"
                      v-model.trim="loginForm.email"
                      label="Adresse e-mail"
                      type="email"
                      outline
                      class="text-left mb-0"
                      size="sm"
                      required
                      invalidFeedback="Veuillez saisir votre adresse e-mail."
                    />
                    <mdb-input
                      v-model.trim="loginForm.password"
                      label="Mot de passe"
                      outline
                      class="text-left my-0"
                      size="sm"
                      type="password"
                      required
                      invalidFeedback="Veuillez saisir votre mot de passe."
                    />
                    <mdb-input
                      v-model.trim="loginForm.secretCode"
                      label="Code MyAgora"
                      outline
                      class="text-left mt-0"
                      size="sm"
                      type="password"
                      required
                      invalidFeedback="Veuillez saisir le code MyAgora."
                    />
                    <vue-recaptcha
                      id="recaptcha"
                      ref="recaptcha"
                      size="invisible"
                      @verify="onCaptchaVerified"
                      @expired="onCaptchaExpired"
                      sitekey="6Leyl70ZAAAAAM7-cdlURA8jaSDIPnjaBNPst8yw"
                    ></vue-recaptcha>
                    <mdb-btn
                      v-if="!isLoading"
                      :disabled="
                        loginForm.email === '' ||
                        loginForm.password === '' ||
                        loginForm.secretCode === ''
                      "
                      class="agora-btn"
                      type="submit"
                      size="sm"
                      color="primary"
                      >Se connecter</mdb-btn
                    >
                    <mdb-spinner v-else small crazy />
                  </form>
                  <!-- /Login Form -->
                </mdb-card-body>
              </mdb-card>
            </mdb-col>
          </mdb-row>
        </mdb-container>
      </mdb-mask>
    </mdb-view>
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
const fb = require("@/firebaseConfig.js");

export default {
  name: "LogIn",
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
        secretCode: "",
        recaptchaToken: "",
      },
      showLoginForm: true,
      isLoading: false,
    };
  },
  methods: {
    checkForm(event) {
      event.target.classList.add("was-validated");

      if (event.target.checkValidity()) {
        this.isLoading = true;
        this.$refs.recaptcha.execute();
      } else {
        this.$notify.error({
          message: "Veuillez renseigner tous les champs demandés.",
          position: "top right",
          timeOut: 2000,
        });
      }
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.loginForm.recaptchaToken = recaptchaToken;

      this.login();
      this.$refs.recaptcha.reset();
    },
    onCaptchaExpired: function () {
      this.isLoading = false;
      this.$refs.recaptcha.reset();
    },
    login() {
      this.isLoading = true;

      fb.auth
        .signInWithEmailAndPassword(
          this.loginForm.email.trim(),
          this.loginForm.password.trim()
        )
        .then((cred) => {
          fb.adminCodesCollection
            .where(
              "allowedEmails",
              "array-contains",
              this.loginForm.email.trim()
            )
            .where("status", "==", "bimbim")
            .where("secretCode", "==", this.loginForm.secretCode.trim())
            .get()
            .then((docs) => {
              if (docs.size > 0) {
                var codeOk = false;

                docs.forEach(async (doc) => {
                  if (doc.exists) {
                    var code = doc.data();

                    if (code.validity >= Date.now()) {
                      codeOk = true;
                      this.isLoading = false;
                    } else {
                      this.isLoading = false;
                      this.$notify.error({
                        message:
                          "Désolé, la date de validité de ce code est expirée.",
                        position: "top right",
                        timeOut: 2000,
                      });
                    }

                    if (codeOk) {
                      this.$store.commit("setFirebaseUser", cred.user);
                      this.$store.dispatch("getUserProfile");
                      this.isLoading = false;
                      this.$router.push("/dashboard").catch(() => {});
                    } else {
                      this.isLoading = false;
                      this.$notify.error({
                        message:
                          "Votre code est erroné ou expiré, veuillez réessayer",
                        position: "top right",
                        timeOut: 2000,
                      });
                    }
                  } else {
                    this.isLoading = false;
                    this.$notify.error({
                      message:
                        "Désolé, ce code ne correspond à aucun compte actif.",
                      position: "top right",
                      timeOut: 2000,
                    });
                  }
                });
              } else {
                this.isLoading = false;
                this.$notify.error({
                  message:
                    "Désolé, ce code ne correspond à aucun compte actif.",
                  position: "top right",
                  timeOut: 2000,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              this.isLoading = false;
              this.$notify.error({
                message: "Une erreur est survenue, veuillez réessayer.",
                position: "top right",
                timeOut: 2000,
              });
            });
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.$notify.error({
            message: "Une erreur est survenue, veuillez réessayer.",
            position: "top right",
            timeOut: 2000,
          });
        });
    },
  },
};
</script> 

<style scoped lang="scss">
@import "@/assets/_custom.scss";

.btn {
  min-width: 180px;
}

.view {
  background-image: url("~@/assets/participation_citoyenne.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100vh;
}

.my-agora-logo {
  width: 180px;
}

#recaptcha {
  visibility: hidden;
}

@media only screen and (max-width: 767px) {
  .my-agora-logo {
    display: inline;
  }
}

@media only screen and (max-width: 480px) {
  .my-agora-logo {
    display: inline;
  }
}
</style>