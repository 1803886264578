import Vue from 'vue'
import Vuex from 'vuex'
const fb = require('./firebaseConfig.js')

Vue.use(Vuex)

fb.auth.onAuthStateChanged(fbUser => {
  if (fbUser) {
    store.commit('setFirebaseUser', fbUser);
    store.dispatch('getUserProfile');
  } else {
    store.dispatch('clearData');
  }
})

export const store = new Vuex.Store({
  state: {
    // Auth
    userIsAuthentified: false,
    // Main
    firebaseUser: null,
    userProfile: {},
    userCommunes: [],
    userEpci: {},
    userEpciCommunes: [],
    userActivity: [],
    userActivityEvents: [],
    userActivityNews: [],
    userActivityPublications: [],
    otherUserActivity: [],
    otherUserActivityEvents: [],
    otherUserActivityNews: [],
    otherUserActivityPublications: [],
    // Actions
    actions: [],
    forms: [],
    questions: [],
    // Agoras
    agoras: [],
    openedAgoras: [],
    savedAgoras: [],
    closedAgoras: [],
    temporaryAgora: null,
    // Alerts
    alerts: [],
    temporaryAlert: null,
    // Areas
    areas: [],
    communes: [],
    countries: [],
    departements: [],
    epcis: [],
    regions: [],
    // Consultations
    consultations: [],
    openedConsultations: [],
    savedConsultations: [],
    closedConsultations: [],
    epciConsultations: [],
    electedMemberConsultations: [],
    professionalConsultations: [],
    associationConsultations: [],
    temporaryConsultation: null,
    // Demandes
    demandes: [],
    // Events
    events: [],
    openedEvents: [],
    savedEvents: [],
    closedEvents: [],
    epciEvents: [],
    electedMemberEvents: [],
    professionalEvents: [],
    associationEvents: [],
    temporaryEvent: null,
    // Ideas
    ideas: [],
    temporaryIdea: null,
    // Interactions
    comments: [],
    interactions: [],
    notes: [],
    responses: [],
    // News
    news: [],
    temporaryNews: null,
    // Surveys
    surveys: [],
    openedSurveys: [],
    closedSurveys: [],
    temporarySurvey: null,
    // Users
    users: [],
    associations: [],
    citizens: [],
    electedMembers: [],
    epciDirectors: [],
    professionals: [],
  },
  actions: {
    clearData({ commit }) {
      // Main
      commit('setFirebaseUser', null)
      commit('setUserProfile', {})
      commit('setUserCommunes', [])
      commit('setUserEpci', {})
      commit('setUserEpciCommunes', [])
      commit('setUserActivity', [])
      commit('setUserActivityEvents', [])
      commit('setUserActivityNews', [])
      commit('setUserActivityPublications', [])
      commit('setTemporaryAgora', null)
      commit('setTemporaryAlert', null)
      commit('setTemporaryConsultation', null)
      commit('setTemporaryEvent', null)
      commit('setTemporaryIdea', null)
      commit('setTemporaryNews', null)
      commit('setTemporarySurvey', null)
    },
    getUserProfile({ commit, state }) {
      fb.usersCollection.doc(state.firebaseUser.uid).get().then(snapshot => {
        let user = snapshot.data();

        commit('setUserProfile', user)

        // switch (user.status) {
        //   case 'communeAccount':
        //     store.dispatch('getUserCommune');
        //     break;
        //   case 'electedMember':
        //     store.dispatch('getUserCommune');
        //     break;
        //   case 'epciAccount':
        //     store.dispatch('getUserEpci', {
        //       siren: state.userProfile.epciSiren
        //     });
        //     store.dispatch('getUserCommunes');
        //     break;
        // }
      }).catch(err => {
        console.log(err)
      })
    },
    // COMMUNE ACCOUNT - ELECTED MEMBER : Get user single commune
    getUserCommune({ commit, state }) {
      fb.communesCollection.where('uuid', '==', state.userProfile.communesUuid[0]).get()
        .then(snapshots => {
          if (snapshots.size > 0) {
            snapshots.forEach(snapshot => {
              const commune = snapshot.data();
              // TODO: remettre ca pour supprimer Foix Demo
              // if (typeof commune.isDemo === "undefined" || commune.isDemo == null || !commune.isDemo) {
              var userCommunes = [];
              userCommunes.push(commune)
              commit('setUserCommunes', userCommunes);

              store.dispatch('getUserEpci', {
                siren: commune.epciSiren
              });
              store.dispatch('getUserEpciCommunes', {
                siren: commune.epciSiren
              });
              // }
            });
          }
        })
        .catch(err => {
          console.log('Une erreur est survenue, veuillez réessayer', err);
        });
    },
    // EPCI ACCOUNT : Get user all epcis communes
    getUserCommunes({ commit, state }) {
      fb.communesCollection.where('epciSiren', '==', state.userProfile.epciSiren).onSnapshot(querySnapshot => {
        var userCommunes = [];
        var counter = 0;

        querySnapshot.forEach(doc => {
          var commune = doc.data();

          // TODO: remettre ca pour supprimer Foix Demo
          // if (typeof commune.isDemo === "undefined" || commune.isDemo == null || !commune.isDemo) {
          userCommunes.push(commune);
          // }

          counter = counter + 1;
        });

        if (counter == querySnapshot.size) {
          commit('setUserCommunes', userCommunes)
          commit('setUserEpciCommunes', userCommunes)
        }
      });
    },
    // Get Epci with siren number
    getUserEpci({ commit }, data) {
      fb.epcisCollection.where('siren', '==', data.siren).onSnapshot(snapshot => {
        snapshot.forEach(doc => {
          var epci = doc.data();
          commit('setUserEpci', epci);
        });
      })
    },
    // Get all communes from Epci with siren number
    getUserEpciCommunes({ commit }, data) {
      fb.communesCollection.where('epciSiren', '==', data.siren).onSnapshot(querySnapshot => {
        var userEpciCommunes = [];
        var counter = 0;

        querySnapshot.forEach(doc => {
          var commune = doc.data();

          // TODO: remettre ca pour supprimer Foix Demo
          // if (typeof commune.isDemo === "undefined" || commune.isDemo == null || !commune.isDemo) {
          userEpciCommunes.push(commune);
          // }

          counter = counter + 1;
        });

        if (counter == querySnapshot.size) {
          commit('setUserEpciCommunes', userEpciCommunes)
        }
      });
    },
    // Get all user activity (creations / comments / notes / etc...)
    async getUserActivity({ commit }, data) {
      // TODO: AMELIORATION: Manque plein de choses (traitement / participation / forms ...)

      var activity = [];
      var activityEvents = [];
      var activityNews = [];
      var activityPublications = [];

      // Agoras
      fb.agorasCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var agora = doc.data()
            activity.push({
              key: "CREATED",
              value: agora,
              actionDate: agora.creationDate
            });
          });
        });

      // Alerts
      fb.alertsCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var alert = doc.data()
            activity.push({
              key: "CREATED",
              value: alert,
              actionDate: alert.creationDate
            });
            activityPublications.push({
              key: "CREATED",
              value: alert,
              actionDate: alert.creationDate,
            });
          });
        });

      // Consultations
      fb.consultationsCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var consultation = doc.data()
            activity.push({
              key: "CREATED",
              value: consultation,
              actionDate: consultation.creationDate
            });
          });
        });

      // Events
      fb.eventsCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var event = doc.data()
            activity.push({
              key: "CREATED",
              value: event,
              actionDate: event.creationDate
            });
            activityEvents.push({
              key: "CREATED",
              value: event,
              actionDate: event.creationDate,
            });
          });
        });

      // Ideas
      fb.ideasCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var idea = doc.data()
            activity.push({
              key: "CREATED",
              value: idea,
              actionDate: idea.creationDate
            });
            activityPublications.push({
              key: "CREATED",
              value: idea,
              actionDate: idea.creationDate,
            });
          });
        });

      // News
      fb.newsCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var news = doc.data()
            activity.push({
              key: "CREATED",
              value: news,
              actionDate: news.creationDate
            });
            activityNews.push({
              key: "CREATED",
              value: news,
              actionDate: news.creationDate,
            });
          });
        });

      // Surveys
      fb.surveysCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var survey = doc.data()
            activity.push({
              key: "CREATED",
              value: survey,
              actionDate: survey.creationDate
            });
          });
        });

      // Comments
      fb.commentsCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var comment = doc.data()

            switch (comment.actionStatus) {
              case "agora":
                fb.agorasCollection.where('uuid', '==', comment.actionUuid)
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                      var agora = doc.data()
                      activity.push({
                        key: "COMMENTED",
                        value: agora,
                        actionDate: comment.creationDate
                      });
                    });
                  });
                break;
              case "alert":
                fb.alertsCollection.where('uuid', '==', comment.actionUuid)
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                      var alert = doc.data()
                      activity.push({
                        key: "COMMENTED",
                        value: alert,
                        actionDate: comment.creationDate
                      });
                    });
                  });
                break;
              case "consultation" || "results":
                fb.consultationsCollection.where('uuid', '==', comment.actionUuid)
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                      var consultation = doc.data()
                      activity.push({
                        key: "COMMENTED",
                        value: consultation,
                        actionDate: comment.creationDate
                      });
                    });
                  });
                break;
              case "event":
                fb.eventsCollection.where('uuid', '==', comment.actionUuid)
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                      var event = doc.data()
                      activity.push({
                        key: "COMMENTED",
                        value: event,
                        actionDate: comment.creationDate
                      });
                    });
                  });
                break;
              case "idea":
                fb.ideasCollection.where('uuid', '==', comment.actionUuid)
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                      var idea = doc.data()
                      activity.push({
                        key: "COMMENTED",
                        value: idea,
                        actionDate: comment.creationDate
                      });
                    });
                  });
                break;
              case "news":
                fb.newsCollection.where('uuid', '==', comment.actionUuid)
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                      var news = doc.data()
                      activity.push({
                        key: "COMMENTED",
                        value: news,
                        actionDate: comment.creationDate
                      });
                    });
                  });
                break;
            }
          });
        });

      // Notes
      fb.notesCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var note = doc.data()

            switch (note.actionStatus) {
              case "consultation" || "results":
                fb.consultationsCollection.where('uuid', '==', note.actionUuid)
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                      var consultation = doc.data()
                      activity.push({
                        key: "NOTED",
                        value: consultation,
                        actionDate: note.creationDate
                      });
                    });
                  });
                break;
              case "idea":
                fb.ideasCollection.where('uuid', '==', note.actionUuid)
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                      var idea = doc.data()
                      activity.push({
                        key: "NOTED",
                        value: idea,
                        actionDate: note.creationDate
                      });
                    });
                  });
                break;
            }
          });
        });

      commit('setUserActivity', activity)
      commit('setUserActivityEvents', activityEvents)
      commit('setUserActivityNews', activityNews)
      commit('setUserActivityPublications', activityPublications)
    },
    async getOtherUserActivity({ commit }, data) {
      // TODO: AMELIORATION: Manque plein de choses (traitement / participation / forms ...)

      var activity = [];
      var activityEvents = [];
      var activityNews = [];
      var activityPublications = [];

      // Agoras
      fb.agorasCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var agora = doc.data()
            activity.push({
              key: "CREATED",
              value: agora,
              actionDate: agora.creationDate
            });
          });
        });

      // Alerts
      fb.alertsCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var alert = doc.data()
            activity.push({
              key: "CREATED",
              value: alert,
              actionDate: alert.creationDate
            });
            activityPublications.push({
              key: "CREATED",
              value: alert,
              actionDate: alert.creationDate,
            });
          });
        });

      // Consultations
      fb.consultationsCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var consultation = doc.data()
            activity.push({
              key: "CREATED",
              value: consultation,
              actionDate: consultation.creationDate
            });
          });
        });

      // Events
      fb.eventsCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var event = doc.data()
            activity.push({
              key: "CREATED",
              value: event,
              actionDate: event.creationDate
            });
            activityEvents.push({
              key: "CREATED",
              value: event,
              actionDate: event.creationDate,
            });
          });
        });

      // Ideas
      fb.ideasCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var idea = doc.data()
            activity.push({
              key: "CREATED",
              value: idea,
              actionDate: idea.creationDate
            });
            activityPublications.push({
              key: "CREATED",
              value: idea,
              actionDate: idea.creationDate,
            });
          });
        });

      // News
      fb.newsCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var news = doc.data()
            activity.push({
              key: "CREATED",
              value: news,
              actionDate: news.creationDate
            });
            activityNews.push({
              key: "CREATED",
              value: news,
              actionDate: news.creationDate,
            });
          });
        });

      // Surveys
      fb.surveysCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var survey = doc.data()
            activity.push({
              key: "CREATED",
              value: survey,
              actionDate: survey.creationDate
            });
          });
        });

      // Comments
      fb.commentsCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var comment = doc.data()

            switch (comment.actionStatus) {
              case "agora":
                fb.agorasCollection.where('uuid', '==', comment.actionUuid)
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                      var agora = doc.data()
                      activity.push({
                        key: "COMMENTED",
                        value: agora,
                        actionDate: comment.creationDate
                      });
                    });
                  });
                break;
              case "alert":
                fb.alertsCollection.where('uuid', '==', comment.actionUuid)
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                      var alert = doc.data()
                      activity.push({
                        key: "COMMENTED",
                        value: alert,
                        actionDate: comment.creationDate
                      });
                    });
                  });
                break;
              case "consultation" || "results":
                fb.consultationsCollection.where('uuid', '==', comment.actionUuid)
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                      var consultation = doc.data()
                      activity.push({
                        key: "COMMENTED",
                        value: consultation,
                        actionDate: comment.creationDate
                      });
                    });
                  });
                break;
              case "event":
                fb.eventsCollection.where('uuid', '==', comment.actionUuid)
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                      var event = doc.data()
                      activity.push({
                        key: "COMMENTED",
                        value: event,
                        actionDate: comment.creationDate
                      });
                    });
                  });
                break;
              case "idea":
                fb.ideasCollection.where('uuid', '==', comment.actionUuid)
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                      var idea = doc.data()
                      activity.push({
                        key: "COMMENTED",
                        value: idea,
                        actionDate: comment.creationDate
                      });
                    });
                  });
                break;
              case "news":
                fb.newsCollection.where('uuid', '==', comment.actionUuid)
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                      var news = doc.data()
                      activity.push({
                        key: "COMMENTED",
                        value: news,
                        actionDate: comment.creationDate
                      });
                    });
                  });
                break;
            }
          });
        });

      // Notes
      fb.notesCollection.where('creatorUuid', '==', data.userUuid)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var note = doc.data()

            switch (note.actionStatus) {
              case "consultation" || "results":
                fb.consultationsCollection.where('uuid', '==', note.actionUuid)
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                      var consultation = doc.data()
                      activity.push({
                        key: "NOTED",
                        value: consultation,
                        actionDate: note.creationDate
                      });
                    });
                  });
                break;
              case "idea":
                fb.ideasCollection.where('uuid', '==', note.actionUuid)
                  .get()
                  .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                      var idea = doc.data()
                      activity.push({
                        key: "NOTED",
                        value: idea,
                        actionDate: note.creationDate
                      });
                    });
                  });
                break;
            }
          });
        });

      commit('setOtherUserActivity', activity)
      commit('setOtherUserActivityEvents', activityEvents)
      commit('setOtherUserActivityNews', activityNews)
      commit('setOtherUserActivityPublications', activityPublications)
    },
    // Get actions (agoras / alerts / consultations / events / ideas) for markers
    async getActions({ commit, state }) {
      var actions = [];

      if (state.userProfile.status === 'epciAccount') {
        // If EPCI => get everything from each EPCI commune with communeUuid ref
        state.userCommunes.forEach(commune => {
          fb.agorasCollection.where('communeUuid', '==', commune.uuid)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                var agora = doc.data()
                actions.push(agora)
              });
            });

          fb.alertsCollection.where('visibility', 'array-contains', commune.uuid)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                var alert = doc.data()
                actions.push(alert)
              });
            });

          fb.consultationsCollection.where('visibility', 'array-contains', commune.uuid)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                var consultation = doc.data()
                actions.push(consultation)
              });
            });

          fb.eventsCollection.where('visibility', 'array-contains', commune.uuid)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                var event = doc.data()
                actions.push(event)
              });
            });

          fb.ideasCollection.where('visibility', 'array-contains', commune.uuid)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                var idea = doc.data()
                actions.push(idea)
              });
            });
        });
      } else {
        // If Commune or ElectedMember => get everything from each user commune with visibility ref
        state.userCommunes.forEach(commune => {
          fb.agorasCollection.where('visibility', 'array-contains', commune.uuid)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                var agora = doc.data()
                actions.push(agora)
              });
            });

          fb.alertsCollection.where('visibility', 'array-contains', commune.uuid)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                var alert = doc.data()
                actions.push(alert)
              });
            });

          fb.consultationsCollection.where('visibility', 'array-contains', commune.uuid)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                var consultation = doc.data()
                actions.push(consultation)
              });
            });

          fb.eventsCollection.where('visibility', 'array-contains', commune.uuid)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                var event = doc.data()
                actions.push(event)
              });
            });

          fb.ideasCollection.where('visibility', 'array-contains', commune.uuid)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                var idea = doc.data()
                actions.push(idea)
              });
            });
        });
      }

      commit('setActions', actions)
    },
    async getConsultations({ commit }) {
      var consultations = []

      fb.consultationsCollection
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var consultation = doc.data()
            consultations.push(consultation)
          })
        })

      commit('setConsultations', consultations)
    },
    async getAgoras({ commit }) {
      var agoras = []

      fb.agorasCollection
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var agora = doc.data()
            agoras.push(agora)
          })
        })

      commit('setAgoras', agoras)
    },
    async getSurveys({ commit }) {
      var surveys = []

      fb.surveysCollection
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var survey = doc.data()
            surveys.push(survey)
          })
        })

      commit('setSurveys', surveys)
    },
    async getEvents({ commit }) {
      var events = []

      fb.eventsCollection
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var event = doc.data()
            events.push(event)
          })
        })

      commit('setEvents', events)
    },
    async getIdeas({ commit }) {
      var ideas = []

      fb.ideasCollection
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var idea = doc.data()
            ideas.push(idea)
          })
        })

      commit('setIdeas', ideas)
    },
    async getAlerts({ commit }) {
      var alerts = []

      fb.alertsCollection
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var alert = doc.data()
            alerts.push(alert)
          })
        })

      commit('setAlerts', alerts)
    },
    async getNews({ commit }) {
      var news = []

      fb.newsCollection
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var actu = doc.data()
            news.push(actu)
          })
        })

      commit('setNews', news)
    },
    async getUsers({ commit }) {
      var users = []

      fb.usersCollection
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var user = doc.data()
            users.push(user)
          })
        })

      commit('setUsers', users)
    },
    async getComments({ commit }) {
      var comments = []

      fb.commentsCollection
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var comment = doc.data()
            comments.push(comment)
          })
        })

      commit('setComments', comments)
    },
    async getDemandes({ commit }) {
      var demandes = []

      fb.demandesCollection
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            var demande = doc.data()
            demandes.push(demande)
          })
        })

      commit('setDemandes', demandes)
    },
    // Update EPCI Info
    updateEpciAccount(data) {
      let updatedEpci = data.epciToUpdate;
      let imageUrl = updatedEpci.imageUrl;
      let description = updatedEpci.description;
      let phone = updatedEpci.phone;
      let website = updatedEpci.website;
      let president = updatedEpci.president;
      let usefulLinks = updatedEpci.usefulLinks;

      fb.epcisCollection.doc(updatedEpci.uuid).update({ imageUrl, description, phone, website, president, usefulLinks })
    },
    // Update Commune Info
    updateCommuneAccount(data) {
      let updatedCommune = data.communeToUpdate;
      let imageUrl = updatedCommune.imageUrl;
      let description = updatedCommune.description;
      let phone = updatedCommune.phone;
      let website = updatedCommune.website;
      let mayor = updatedCommune.mayor;
      let usefulLinks = updatedCommune.usefulLinks;

      fb.communesCollection.doc(updatedCommune.uuid).update({ imageUrl, description, phone, website, mayor, usefulLinks })
    },
    // Update User Profile Info
    updateUserProfile({ state }, data) {
      let updatedUser = data.profileToUpdate;
      let phone = updatedUser.phone;
      let address = updatedUser.address;
      let avatarUrl = updatedUser.avatarUrl;
      let position = updatedUser.position;

      fb.usersCollection.doc(state.firebaseUser.uid).update({ phone, address, avatarUrl, position })
    },
    updateUserToken({ state }, data) {
      let fcmToken = data.fcmToken.toString()

      fb.usersCollection.doc(state.firebaseUser.uid).update({ fcmToken })
    },
    updatePassword(data) {
      let email = data.email.toString()

      fb.auth.sendPasswordResetEmail(email)
    },
    updateCommuneInfo({ state }, data) {
      let website = data.website.toString()
      let mail = data.mail.toString()
      let presentation = data.presentation.toString()

      fb.communesInfoCollection.doc(state.userCommuneInfo.uuid).update({ website, mail, presentation })
    },
    setTemporaryAgora({ commit }, data) {
      commit('setTemporaryAgora', data.agora)
    },
    clearTemporaryAgora({ commit }) {
      commit('setTemporaryAgora', null)
    },
    setTemporaryAlert({ commit }, data) {
      commit('setTemporaryAlert', data.alert)
    },
    clearTemporaryAlert({ commit }) {
      commit('setTemporaryAlert', null)
    },
    setTemporaryConsultation({ commit }, data) {
      commit('setTemporaryConsultation', data.consultation)
    },
    clearTemporaryConsultation({ commit }) {
      commit('setTemporaryConsultation', null)
    },
    setTemporaryEvent({ commit }, data) {
      commit('setTemporaryEvent', data.event)
    },
    clearTemporaryEvent({ commit }) {
      commit('setTemporaryEvent', null)
    },
    setTemporaryIdea({ commit }, data) {
      commit('setTemporaryIdea', data.idea)
    },
    clearTemporaryIdea({ commit }) {
      commit('setTemporaryIdea', null)
    },
    setTemporaryNews({ commit }, data) {
      commit('setTemporaryNews', data.news)
    },
    clearTemporaryNews({ commit }) {
      commit('setTemporaryNews', null)
    },
    setTemporarySurvey({ commit }, data) {
      commit('setTemporarySurvey', data.survey)
    },
    clearTemporarySurvey({ commit }) {
      commit('setTemporarySurvey', null)
    },
    clearTemporaryObjects({ commit }) {
      commit('setTemporaryAgora', null)
      commit('setTemporaryAlert', null)
      commit('setTemporaryConsultation', null)
      commit('setTemporaryEvent', null)
      commit('setTemporaryIdea', null)
      commit('setTemporaryNews', null)
      commit('setTemporarySurvey', null)
    }
  },
  mutations: {
    // Auth
    setUserAuthentication(state, val) {
      state.userIsAuthentified = val
    },
    // Main
    setFirebaseUser(state, val) {
      state.firebaseUser = val
    },
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setUserCommunes(state, val) {
      state.userCommunes = val

      store.dispatch("getActions")
    },
    setUserEpci(state, val) {
      state.userEpci = val
    },
    setUserEpciCommunes(state, val) {
      state.userEpciCommunes = val
    },
    setUserActivity(state, val) {
      state.userActivity = val
    },
    setUserActivityEvents(state, val) {
      state.userActivityEvents = val
    },
    setUserActivityNews(state, val) {
      state.userActivityNews = val
    },
    setUserActivityPublications(state, val) {
      state.userActivityPublications = val
    },
    setOtherUserActivity(state, val) {
      state.otherUserActivity = val
    },
    setOtherUserActivityEvents(state, val) {
      state.otherUserActivityEvents = val
    },
    setOtherUserActivityNews(state, val) {
      state.otherUserActivityNews = val
    },
    setOtherUserActivityPublications(state, val) {
      state.otherUserActivityPublications = val
    },
    // Actions
    setActions(state, val) {
      state.actions = val
    },
    // forms: [],
    // questions: [],
    // Areas
    // areas: [],
    // communes: [],
    // countries: [],
    // departements: [],
    // epcis: [],
    // regions: [],
    // Interactions
    // comments: [],
    // interactions: [],
    // notes: [],
    // responses: [],
    // Agoras
    setAgoras(state, val) {
      state.agoras = val
    },
    setOpenedAgoras(state, val) {
      state.openedAgoras = val
    },
    setSavedAgoras(state, val) {
      state.savedAgoras = val
    },
    setClosedAgoras(state, val) {
      state.closedAgoras = val
    },
    setTemporaryAgora(state, val) {
      state.temporaryAgora = val
    },
    // Alerts
    setAlerts(state, val) {
      state.alerts = val
    },
    setTemporaryAlert(state, val) {
      state.temporaryAlert = val
    },
    // Comments
    setComments(state, val) {
      state.comments = val
    },
    // Consultations
    setConsultations(state, val) {
      state.consultations = val
    },
    setOpenedConsultations(state, val) {
      state.openedConsultations = val
    },
    setSavedConsultations(state, val) {
      state.savedConsultations = val
    },
    setClosedConsultations(state, val) {
      state.closedConsultations = val
    },
    setEpciConsultations(state, val) {
      state.epciConsultations = val
    },
    setElectedMemberConsultations(state, val) {
      state.electedMemberConsultations = val
    },
    setProfessionalConsultations(state, val) {
      state.professionalConsultations = val
    },
    setAssociationConsultations(state, val) {
      state.associationConsultations = val
    },
    setTemporaryConsultation(state, val) {
      state.temporaryConsultation = val
    },
    // Demandes
    setDemandes(state, val) {
      state.demandes = val
    },
    // Events
    setEvents(state, val) {
      state.events = val
    },
    setOpenedEvents(state, val) {
      state.openedEvents = val
    },
    setSavedEvents(state, val) {
      state.savedEvents = val
    },
    setClosedEvents(state, val) {
      state.closedEvents = val
    },
    setEpciEvents(state, val) {
      state.epciEvents = val
    },
    setElectedMemberEvents(state, val) {
      state.electedMemberEvents = val
    },
    setProfessionalEvents(state, val) {
      state.professionalEvents = val
    },
    setAssociationEvents(state, val) {
      state.associationEvents = val
    },
    setTemporaryEvent(state, val) {
      state.temporaryEvent = val
    },
    // Ideas
    setIdeas(state, val) {
      state.ideas = val
    },
    setTemporaryIdea(state, val) {
      state.temporaryIdea = val
    },
    // News
    setNews(state, val) {
      state.news = val
    },
    // setOpenedNews(state, val) {
    //     state.openedNews = val
    // },
    // setSavedNews(state, val) {
    //     state.savedNews = val
    // },
    // setEpciNews(state, val) {
    //     state.epciNews = val
    // },
    // setElectedMemberNews(state, val) {
    //     state.electedMemberNews = val
    // },
    // setProfessionalNews(state, val) {
    //     state.professionalNews = val
    // },
    // setAssociationNews(state, val) {
    //     state.associationNews = val
    // },
    setTemporaryNews(state, val) {
      state.temporaryNews = val
    },
    // Surveys
    setSurveys(state, val) {
      state.surveys = val
    },
    setOpenedSurveys(state, val) {
      state.openedSurveys = val
    },
    setClosedSurveys(state, val) {
      state.closedSurveys = val
    },
    setTemporarySurvey(state, val) {
      state.temporarySurvey = val
    },
    // Users
    setUsers(state, val) {
      state.users = val
    },
    setAssociations(state, val) {
      state.associations = val
    },
    setCitizens(state, val) {
      state.citizens = val
    },
    setElectedMembers(state, val) {
      state.electedMembers = val
    },
    setEpciDirectors(state, val) {
      state.epciDirectors = val
    },
    setProfessionals(state, val) {
      state.professionals = val
    },
  },
  getters: {
    // Main
    getUserCommunes: state => state.userCommunes,
    getUserEpci: state => state.userEpci,
    getUserEpciCommunes: state => state.userEpciCommunes,
    getUserActivity: state => state.userActivity,
    getUserActivityEvents: state => state.userActivityEvents,
    getUserActivityNews: state => state.userActivityNews,
    getUserActivityPublications: state => state.userActivityPublications,
    getOtherUserActivity: state => state.otherUserActivity,
    getOtherUserActivityEvents: state => state.otherUserActivityEvents,
    getOtherUserActivityNews: state => state.otherUserActivityNews,
    getOtherUserActivityPublications: state => state.otherUserActivityPublications,
    // Actions
    getActions: state => state.actions,
    getForms: state => state.forms,
    getQuestions: state => state.questions,
    // Agoras
    getAgoras: state => state.agoras,
    getOpenedAgoras: state => state.openedAgoras,
    getSavedAgoras: state => state.savedAgoras,
    getClosedAgoras: state => state.closedAgoras,
    getTemporaryAgora: state => state.temporaryAgora,
    // Alerts
    getAlerts: state => state.alerts,
    getTemporaryAlert: state => state.temporaryAlert,
    // Areas
    getAreas: state => state.areas,
    getCommunes: state => state.communes,
    getCountries: state => state.countries,
    getDepartements: state => state.departements,
    getEpcis: state => state.epcis,
    getRegions: state => state.regions,
    // Consultations
    getConsultations: state => state.consultations,
    getOpenedConsultations: state => state.openedConsultations,
    getSavedConsultations: state => state.savedConsultations,
    getClosedConsultations: state => state.closedConsultations,
    getEpciConsultations: state => state.epciConsultations,
    getElectedMemberConsultations: state => state.electedMemberConsultations,
    getProfessionalConsultations: state => state.professionalConsultations,
    getAssociationConsultations: state => state.associationConsultations,
    getTemporaryConsultation: state => state.temporaryConsultation,
    // Demandes
    getDemandes: state => state.demandes,
    // Surveys
    getSurveys: state => state.surveys,
    getOpenedSurveys: state => state.openedSurveys,
    getClosedSurveys: state => state.closedSurveys,
    getTemporarySurvey: state => state.temporarySurvey,
    // Events
    getEvents: state => state.events,
    getOpenedEvents: state => state.openedEvents,
    getSavedEvents: state => state.savedEvents,
    getClosedEvents: state => state.closedEvents,
    getEpciEvents: state => state.epciEvents,
    getElectedMemberEvents: state => state.electedMemberEvents,
    getProfessionalEvents: state => state.professionalEvents,
    getAssociationEvents: state => state.associationEvents,
    getTemporaryEvent: state => state.temporaryEvent,
    // Ideas
    getIdeas: state => state.ideas,
    getTemporaryIdea: state => state.temporaryIdea,
    // Interactions
    getComments: state => state.comments,
    getInteractions: state => state.interactions,
    getNotes: state => state.notes,
    getResponses: state => state.responses,
    // News
    getNews: state => state.news,
    getTemporaryNews: state => state.temporaryNews,
    // Users
    getUsers: state => state.users,
    getAssociations: state => state.associations,
    getCitizens: state => state.citizens,
    getElectedMembers: state => state.electedMembers,
    getEpciDirectors: state => state.epciDirectors,
    getProfessionals: state => state.professionals,
  }
});