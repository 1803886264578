<template>
  <div class="home">
    <LogIn />
  </div>
</template>

<script>
import LogIn from "@/components/welcome/LogIn.vue";

export default {
  name: "home",
  components: {
    LogIn,
  },
};
</script>
