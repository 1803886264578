<template>
  <div id="dashboard" class="main mt-5 p-5">
    <!-- Title -->
    <mdb-row class="justify-content-between align-items-center">
      <h1 class="h3-responsive font-weight-bold">Accueil</h1>
    </mdb-row>
    <!-- /Title -->

    <!-- Data -->
    <mdb-row class="justify-content-center align-items-center">
      <mdb-col col="3" class="my-3">
        <p class="text-secondary"><strong class="mr-1">Consultations</strong><mdb-badge pill color="primary">{{ this.consultations.length }}</mdb-badge></p>
        <mdb-pie-chart
          class="my-3 mx-0 p-0"
          datalabels
          :data="consultationsData"
          :options="consultationsOptions"
          :height="160"
          :width="160"
        />
        <mdb-btn @click="goToConsultations" outline="primary" size="sm"
          >Voir plus</mdb-btn
        >
      </mdb-col>
      <mdb-col col="3" class="my-3">
        <p class="text-secondary"><strong class="mr-1">Agoras citoyennes</strong><mdb-badge pill color="primary">{{ this.agoras.length }}</mdb-badge></p>
        <mdb-pie-chart
          class="my-3 mx-0 p-0"
          datalabels
          :data="agorasData"
          :options="agorasOptions"
          :height="160"
          :width="160"
        />
        <mdb-btn @click="goToAgoras" outline="primary" size="sm"
          >Voir plus</mdb-btn
        >
      </mdb-col>
      <mdb-col col="3" class="my-3">
        <p class="text-secondary"><strong class="mr-1">Sondages</strong><mdb-badge pill color="primary">{{ this.surveys.length }}</mdb-badge></p>
        <mdb-pie-chart
          class="my-3 mx-0 p-0"
          datalabels
          :data="surveysData"
          :options="surveysOptions"
          :height="160"
          :width="160"
        />
        <mdb-btn @click="goToSurveys" outline="primary" size="sm"
          >Voir plus</mdb-btn
        >
      </mdb-col>
      <mdb-col col="3" class="my-3">
        <p class="text-secondary"><strong class="mr-1">Événements</strong><mdb-badge pill color="primary">{{ this.events.length }}</mdb-badge></p>
        <mdb-pie-chart
          class="my-3 mx-0 p-0"
          datalabels
          :data="eventsData"
          :options="eventsOptions"
          :height="160"
          :width="160"
        />
        <mdb-btn @click="goToEvents" outline="primary" size="sm"
          >Voir plus</mdb-btn
        >
      </mdb-col>
    </mdb-row>
    <mdb-row class="justify-content-center align-items-center">
      <mdb-col col="3" class="my-3">
        <p class="text-secondary"><strong class="mr-1">Idées citoyennes</strong><mdb-badge pill color="primary">{{ this.ideas.length }}</mdb-badge></p>
        <mdb-pie-chart
          class="my-3 mx-0 p-0"
          datalabels
          :data="ideasData"
          :options="ideasOptions"
          :height="160"
          :width="160"
        />
        <mdb-btn @click="goToIdeas" outline="primary" size="sm"
          >Voir plus</mdb-btn
        >
      </mdb-col>
      <mdb-col col="3" class="my-3">
        <p class="text-secondary"><strong class="mr-1">Signalements</strong><mdb-badge pill color="primary">{{ this.alerts.length }}</mdb-badge></p>
        <mdb-pie-chart
          class="my-3 mx-0 p-0"
          datalabels
          :data="alertsData"
          :options="alertsOptions"
          :height="160"
          :width="160"
        />
        <mdb-btn @click="goToAlerts" outline="primary" size="sm"
          >Voir plus</mdb-btn
        >
      </mdb-col>
      <mdb-col col="3" class="my-3">
        <p class="text-secondary"><strong class="mr-1">Actualités</strong><mdb-badge pill color="primary">{{ this.news.length }}</mdb-badge></p>
        <mdb-pie-chart
          class="my-3 mx-0 p-0"
          datalabels
          :data="newsData"
          :options="newsOptions"
          :height="160"
          :width="160"
        />
        <mdb-btn @click="goToNews" outline="primary" size="sm"
          >Voir plus</mdb-btn
        >
      </mdb-col>
      <mdb-col col="3" class="my-3">
        <p class="text-secondary"><strong class="mr-1">Utilisateurs</strong><mdb-badge pill color="primary">{{ this.users.length }}</mdb-badge></p>
        <mdb-pie-chart
          class="my-3 mx-0 p-0"
          datalabels
          :data="usersData"
          :options="usersOptions"
          :height="160"
          :width="160"
        />
        <mdb-btn @click="goToUsers" outline="primary" size="sm"
          >Voir plus</mdb-btn
        >
      </mdb-col>
    </mdb-row>
    <!-- /Data -->
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "dashboard",
  data() {
    return {
      consultationsData: {},
      agorasData: {},
      surveysData: {},
      eventsData: {},
      ideasData: {},
      alertsData: {},
      newsData: {},
      usersData: {},
      consultationsOptions: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "white",
            align: "center",
            font: {
              size: 16,
            },
            formatter: (value) => {
              const [dataset] = this.consultationsData.datasets;
              const setValue = dataset.data.reduce((a, b) => a + b);

              return `${Math.round((value / setValue) * 100)}%`;
            },
          },
        },
        legend: {
          display: false,
        },
      },
      agorasOptions: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "white",
            align: "center",
            font: {
              size: 16,
            },
            formatter: (value) => {
              const [dataset] = this.agorasData.datasets;
              const setValue = dataset.data.reduce((a, b) => a + b);

              return `${Math.round((value / setValue) * 100)}%`;
            },
          },
        },
        legend: {
          display: false,
        },
      },
      surveysOptions: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "white",
            align: "center",
            font: {
              size: 16,
            },
            formatter: (value) => {
              const [dataset] = this.surveysData.datasets;
              const setValue = dataset.data.reduce((a, b) => a + b);

              return `${Math.round((value / setValue) * 100)}%`;
            },
          },
        },
        legend: {
          display: false,
        },
      },
      eventsOptions: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "white",
            align: "center",
            font: {
              size: 16,
            },
            formatter: (value) => {
              const [dataset] = this.eventsData.datasets;
              const setValue = dataset.data.reduce((a, b) => a + b);

              return `${Math.round((value / setValue) * 100)}%`;
            },
          },
        },
        legend: {
          display: false,
        },
      },
      ideasOptions: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "white",
            align: "center",
            font: {
              size: 16,
            },
            formatter: (value) => {
              const [dataset] = this.ideasData.datasets;
              const setValue = dataset.data.reduce((a, b) => a + b);

              return `${Math.round((value / setValue) * 100)}%`;
            },
          },
        },
        legend: {
          display: false,
        },
      },
      alertsOptions: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "white",
            align: "center",
            font: {
              size: 16,
            },
            formatter: (value) => {
              const [dataset] = this.alertsData.datasets;
              const setValue = dataset.data.reduce((a, b) => a + b);

              return `${Math.round((value / setValue) * 100)}%`;
            },
          },
        },
        legend: {
          display: false,
        },
      },
      newsOptions: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "white",
            align: "center",
            font: {
              size: 16,
            },
            formatter: (value) => {
              const [dataset] = this.newsData.datasets;
              const setValue = dataset.data.reduce((a, b) => a + b);

              return `${Math.round((value / setValue) * 100)}%`;
            },
          },
        },
        legend: {
          display: false,
        },
      },
      usersOptions: {
        responsive: false,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            color: "white",
            align: "center",
            font: {
              size: 16,
            },
            formatter: (value) => {
              const [dataset] = this.usersData.datasets;
              const setValue = dataset.data.reduce((a, b) => a + b);

              return `${Math.round((value / setValue) * 100)}%`;
            },
          },
        },
        legend: {
          display: false,
        },
      },
    };
  },
  computed: {
    ...mapState([
      "consultations",
      "agoras",
      "surveys",
      "events",
      "ideas",
      "alerts",
      "news",
      "users",
    ]),
  },
  watch: {
    consultations() {
      var consults = [];
      var results = [];

      for (let index = 0; index < this.consultations.length; index++) {
        const consultation = this.consultations[index];
        switch (consultation.status) {
          case "consultation":
            consults.push(consultation);
            break;
          case "results":
            results.push(consultation);
            break;
        }
      }

      this.consultationsData = {
        labels: ["En cours", "Cloturées"],
        datasets: [
          {
            data: [consults.length, results.length],
            backgroundColor: ["#63b4f7", "#6dc412"],
            hoverBackgroundColor: ["#63b4f7", "#6dc412"],
          },
        ],
      };
    },
    agoras() {
      var opened = [];
      var saved = [];
      var closed = [];

      for (let index = 0; index < this.agoras.length; index++) {
        const agora = this.agoras[index];
        switch (agora.type) {
          case "opened":
            opened.push(agora);
            break;
          case "saved":
            saved.push(agora);
            break;
          case "closed":
            closed.push(agora);
            break;
        }
      }

      this.agorasData = {
        labels: ["En cours", "Brouillons", "Terminées"],
        datasets: [
          {
            data: [opened.length, saved.length, closed.length],
            backgroundColor: ["#63b4f7", "#ffc306", "#f44747"],
            hoverBackgroundColor: ["#63b4f7", "#ffc306", "#f44747"],
          },
        ],
      };
    },
    surveys() {
      var opened = [];
      var closed = [];

      for (let index = 0; index < this.surveys.length; index++) {
        const survey = this.surveys[index];
        switch (survey.type) {
          case "opened":
            opened.push(survey);
            break;
          case "closed":
            closed.push(survey);
            break;
        }
      }

      this.surveysData = {
        labels: ["En cours", "Terminés"],
        datasets: [
          {
            data: [opened.length, closed.length],
            backgroundColor: ["#63b4f7", "#ffc306"],
            hoverBackgroundColor: ["#63b4f7", "#ffc306"],
          },
        ],
      };
    },
    events() {
      console.log('events', this.events.length)
      var opened = [];
      var closed = [];

      for (let index = 0; index < this.events.length; index++) {
        const event = this.events[index];
        console.log('event', event)
        switch (event.type) {
          case "opened":
            opened.push(event);
            break;
          case "closed":
            closed.push(event);
            break;
        }
      }

      this.eventsData = {
        labels: ["En cours", "Terminés"],
        datasets: [
          {
            data: [opened.length, closed.length],
            backgroundColor: ["#63b4f7", "#ffc306"],
            hoverBackgroundColor: ["#63b4f7", "#ffc306"],
          },
        ],
      };
    },
    ideas() {
      console.log('ideas', this.ideas.length)
      var opened = [];
      var closed = [];

      for (let index = 0; index < this.ideas.length; index++) {
        const idea = this.ideas[index];
        console.log('idea', idea)
        switch (idea.type) {
          case "opened":
            opened.push(idea);
            break;
          case "closed":
            closed.push(idea);
            break;
        }
      }

      this.ideasData = {
        labels: ["En cours", "Terminées"],
        datasets: [
          {
            data: [opened.length, closed.length],
            backgroundColor: ["#63b4f7", "#ffc306"],
            hoverBackgroundColor: ["#63b4f7", "#ffc306"],
          },
        ],
      };
    },
    alerts() {
      var opened = [];
      var closed = [];

      for (let index = 0; index < this.alerts.length; index++) {
        const alert = this.alerts[index];
        switch (alert.type) {
          case "opened":
            opened.push(alert);
            break;
          case "closed":
            closed.push(alert);
            break;
        }
      }

      this.alertsData = {
        labels: ["En cours", "Terminés"],
        datasets: [
          {
            data: [opened.length, closed.length],
            backgroundColor: ["#63b4f7", "#ffc306"],
            hoverBackgroundColor: ["#63b4f7", "#ffc306"],
          },
        ],
      };
    },
    news() {
      var opened = [];
      var closed = [];

      for (let index = 0; index < this.news.length; index++) {
        const actu = this.news[index];
        switch (actu.type) {
          case "opened":
            opened.push(actu);
            break;
          case "closed":
            closed.push(actu);
            break;
        }
      }

      this.newsData = {
        labels: ["En cours", "Terminées"],
        datasets: [
          {
            data: [opened.length, closed.length],
            backgroundColor: ["#63b4f7", "#ffc306"],
            hoverBackgroundColor: ["#63b4f7", "#ffc306"],
          },
        ],
      };
    },
    users() {
      var associations = [];
      var citizens = [];
      var communeAccounts = [];
      var electedMembers = [];
      var epciAccounts = [];
      var professionals = [];

      for (let index = 0; index < this.users.length; index++) {
        const user = this.users[index];
        switch (user.status) {
          case "association":
            associations.push(user);
            break;
          case "citizen":
            citizens.push(user);
            break;
          case "communeAccount":
            communeAccounts.push(user);
            break;
          case "electedMember":
            electedMembers.push(user);
            break;
          case "epciAccount":
            epciAccounts.push(user);
            break;
          case "professional":
            professionals.push(user);
            break;
        }

        switch (user.statut) {
          case "Architecte":
            professionals.push(user);
            break;
          case "Association":
            associations.push(user);
            break;
          case "Citoyen":
            citizens.push(user);
            break;
          case "Elu":
            electedMembers.push(user);
            break;
        }
      }

      this.usersData = {
        labels: [
          "Associations",
          "Citoyens",
          "Communes",
          "Élus",
          "EPCIs",
          "Professionnels",
        ],
        datasets: [
          {
            data: [
              associations.length,
              citizens.length,
              communeAccounts.length,
              electedMembers.length,
              epciAccounts.length,
              professionals.length,
            ],
            backgroundColor: [
              "#63b4f7",
              "#ffc306",
              "#ffc306",
              "#ff9327",
              "#f44747",
              "2a428d",
            ],
            hoverBackgroundColor: [
              "#63b4f7",
              "#ffc306",
              "#ffc306",
              "#ff9327",
              "#f44747",
              "2a428d",
            ],
          },
        ],
      };
    },
  },
  created() {
    this.$store.dispatch("getConsultations");
    this.$store.dispatch("getAgoras");
    this.$store.dispatch("getSurveys");
    this.$store.dispatch("getEvents");
    this.$store.dispatch("getIdeas");
    this.$store.dispatch("getAlerts");
    this.$store.dispatch("getNews");
    this.$store.dispatch("getUsers");
  },
  methods: {
    goToConsultations() {
      this.$router.push("/dashboard/consultations");
    },
    goToAgoras() {
      this.$router.push("/dashboard/agoras");
    },
    goToSurveys() {
      this.$router.push("/dashboard/surveys");
    },
    goToEvents() {
      this.$router.push("/dashboard/events");
    },
    goToIdeas() {
      this.$router.push("/dashboard/ideas");
    },
    goToAlerts() {
      this.$router.push("/dashboard/alerts");
    },
    goToNews() {
      this.$router.push("/dashboard/news");
    },
    goToUsers() {
      this.$router.push("/dashboard/users");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";
</style>