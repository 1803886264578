import firebase from 'firebase';
import 'firebase/messaging';

const config = {
    apiKey: "AIzaSyB9979on1VTV1EKQ8qjy-YX46TvlXV_nso",
    authDomain: "rugged-truck-232118.firebaseapp.com",
    databaseURL: "https://rugged-truck-232118.firebaseio.com",
    projectId: "rugged-truck-232118",
    storageBucket: "rugged-truck-232118.appspot.com",
    messagingSenderId: "764047130631",
    appId: "1:764047130631:web:96a8ee74a4958cb421eaa2"
}
firebase.initializeApp(config)

const db = firebase.firestore()
const auth = firebase.auth()
const currentUser = auth.currentUser

const adminCodesCollection = db.collection('AdminCodes')
const agorasCollection = db.collection('Agoras')
const alertsCollection = db.collection('Alerts')
const associationsCollection = db.collection('Associations')
const citizensCollection = db.collection('Citizens')
const commentsCollection = db.collection('Comments')
const communesCollection = db.collection('Communes')
const communesInfoCollection = db.collection('CommunesInfo')
const consultationsCollection = db.collection('Consultations')
const countriesCollection = db.collection('Countries')
const demandesCollection = db.collection('DemandesAppAgora')
const departmentsCollection = db.collection('Departments')
const electedMembersCollection = db.collection('ElectedMembers')
const epciDirectorsCollection = db.collection('EpciDirectos')
const epcisCollection = db.collection('Epcis')
const eventsCollection = db.collection('Events')
const formsCollection = db.collection('Forms')
const ideasCollection = db.collection('Ideas')
const newsCollection = db.collection('News')
const notesCollection = db.collection('Notes')
const professionalsCollection = db.collection('Professionals')
const questionsCollection = db.collection('Questions')
const regionsCollection = db.collection('Regions')
const responsesCollection = db.collection('Responses')
const surveysCollection = db.collection('Surveys')
const usersCollection = db.collection('Users')

export {
    db,
    auth,
    currentUser,
    adminCodesCollection,
    agorasCollection,
    alertsCollection,
    associationsCollection,
    citizensCollection,
    commentsCollection,
    communesCollection,
    communesInfoCollection,
    consultationsCollection,
    countriesCollection,
    demandesCollection,
    departmentsCollection,
    electedMembersCollection,
    epciDirectorsCollection,
    epcisCollection,
    eventsCollection,
    formsCollection,
    ideasCollection,
    newsCollection,
    notesCollection,
    professionalsCollection,
    questionsCollection,
    regionsCollection,
    responsesCollection,
    surveysCollection,
    usersCollection
}