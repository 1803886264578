<template>
  <div id="comments" class="main mt-5 p-5">
    <!-- Title -->
    <mdb-row class="justify-content-between align-items-center">
      <h1 class="h3-responsive font-weight-bold">Commentaires</h1>
    </mdb-row>
    <!-- /Title -->

    <!-- Table -->
    <div v-if="!isLoading">
      <mdb-input
        class="mb-0"
        size="sm"
        v-model="search"
        label="Rechercher par contenu"
      />
      <mdb-datatable-2
        v-model="data"
        hover
        :searching="{ value: search, field: 'text' }"
        striped
        bordered
        hoverColor="primary"
        arrows
        class="my-5"
      />
    </div>
    <!-- /Table -->

    <!-- Loading -->
    <mdb-container
      v-else
      class="d-flex justify-content-center align-items-center"
    >
      <mdb-spinner small crazy />
    </mdb-container>
    <!-- /Loading -->
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { mdbDatatable2 } from "mdbvue";

export default {
  name: "Comments",
  components: {
    mdbDatatable2,
  },
  data() {
    return {
      search: "",
      isLoading: true,
      data: {
        columns: [
          {
            label: "Date",
            field: "creationDate",
            sort: "asc",
          },
          {
            label: "Contenu",
            field: "text",
            sort: "asc",
          },
          {
            label: "ActionUuid",
            field: "actionUuid",
            sort: "asc",
          },
          {
            label: "ActionStatus",
            field: "actionStatus",
            sort: "asc",
          },
          {
            label: "Reports",
            field: "reports",
            sort: "asc",
          },
          {
            label: "Likes",
            field: "likes",
            sort: "asc",
          },
          {
            label: "CreatorUuid",
            field: "creatorUuid",
            sort: "asc",
          },
        ],
        rows: [],
      },
    };
  },
  computed: {
    ...mapState(["comments"]),
  },
  watch: {
    comments() {
      for (let index = 0; index < this.comments.length; index++) {
        const comment = this.comments[index];
        var json = {
          creationDate: moment(new Date(comment.creationDate)).format(
            "DD/MM/YYYY"
          ),
          text: comment.text,
          actionUuid: comment.actionUuid,
          actionStatus: comment.actionStatus,
          reports: comment.reports,
          likes: comment.likes,
          creatorUuid: comment.creatorUuid,
        };

        this.data.rows.push(json);
      }

      this.isLoading = false;
    },
  },
  created() {
    this.$store.dispatch("getComments");
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/_custom.scss";
</style>